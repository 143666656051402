<template>
  <Form id="frm_activo" class="row g-3 frm" @submit="activo" autocomplete="off">
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-3">
        <span class="compudev-etiqueta">1</span>&nbsp;&nbsp;Indica el Monto Inicial (desde $100)
      </div>
      <div style="margin-left: 20px; margin-right: 20px;" class="mb-4">
        <CurrencyInput id="valor" name="valor" class="form-control form-control-lg compudev-valor" v-model="valor"
                       @keyup="activo"
                       :options="{locale: 'en-US', currency: 'USD', currencyDisplay: 'symbol', precision: 2, valueRange: { min: 100 }, }"/>
        <ErrorMessage name="valor" class="d-block compudev-error"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-3">
        <span class="compudev-etiqueta">2</span>&nbsp;&nbsp;Elige tiempo de permanencia
      </div>
      <ul class="nav nav-tabs nav-tabs-red justify-content-center" role="tablist">
        <li class="nav-item text-center" role="tablist">
          <a id="tab-30" class="nav-link active-red" data-bs-toggle="tab" href="#sel-dias"
             role="tab" aria-controls="efectivo">DÍAS</a>
        </li>
      </ul>
      <div class="tab-content text-white" id="contenido-tab">
        <div class="tab-pane container fade show active" id="sel-dias" role="tabpanel" aria-labelledby="tab-30">
          <div class="container text-center compudev-pt">
            <div class="col align-self-center mb-3">
              <input type="radio" class="btn-check" name="dias" id="181" autocomplete="off" value="181" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(181)"/>
              <label class="btn btn-outline-light" for="181">180</label>
              <input type="radio" class="btn-check" name="dias" id="270" autocomplete="off" value="270" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(270)"/>
              <label class="btn btn-outline-light" for="270">270</label>
              <input type="radio" class="btn-check" name="dias" id="361" autocomplete="off" value="361" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(361)"/>
              <label class="btn btn-outline-light" for="361">361</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-4">
        Con un rendimiento proyectado del {{ parseInt(tasa_anual * 100) }}% en {{ print_dias }} días recibirás
      </div>
        <div style="margin-left: 20px; margin-right: 20px;">
          <div id="result-act" class="card text-white compudev-bg-result-green mb-3 opacity-10">
            <div class="card-body">
              <div class="col align-self-center compudev-result">
                <span>{{ result }} &nbsp;</span>
              </div>
            </div>
          </div>
        </div>

    </div>
  </Form>
</template>

<script>
import { Form, ErrorMessage  } from 'vee-validate'
import CurrencyInput from '@/components/CurrencyInput'
const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
export default {
  name: "FondoActivo",
  components: {
    Form,
    ErrorMessage,
    CurrencyInput
  },
  data() {
    return {
      tasa_anual: 0.070,
      mostrar: false,
      valor: null,
      txt_dias: null,
      dias: null,
      deposito_mensual: 0.00,
      plazo: null,
      result: null,
      tasa_mensual: 0.00,
      rendimiento: 0.00,
      acumulado: 0.00,
      meses: null,
      el: null,
      print_dias: null,
    }
  },
  methods: {
    sel_dias(val = null){
      if (val != null){
        this.dias = val
      }
      this.dias = this.plazo
      this.meses = this.get_mes(parseInt(this.dias))
      this.activo()
    },
    get_mes(val){
      let resul = null
      switch (val) {
        case 181:
          resul = 6
              break
        case 270:
          resul = 9
              break
        case 361:
          resul = 12
              break
      }
      return resul
    },
    get_dias(){
      if (this.dias != 270){
        this.print_dias = this.dias - 1
      }else{
        this.print_dias = this.dias
      }
    },
    activo(){
      if (this.valor != null && this.dias != null) {
        this.tasa_mensual = (1 + this.tasa_anual) ** (1 / 12) - 1
        this.acumulado = ((0)) / ((1 + this.tasa_anual / 360) ** 30 - 1) + this.valor * (1 + this.tasa_anual / 360) ** (30 * this.meses)
        this.result = formatter.format(this.acumulado)
        this.mostrar = true
        this.get_dias()
        this.el.classList.remove('opacity-10')
      }
    }
  },
  mounted() {
    this.el = document.getElementById('result-act')
  },
}
</script>

<style scoped>

</style>
