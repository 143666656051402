<template>
  <section class="compudev-bg-simulador py-5 text-white">
    <div class="container">
      <h4 class="text-white text-center mb-4"><u>ELIGE EL MONTO QUE SUEÑAS OBTENER</u></h4>
      <Form id="frm_simulador" @submit="simulador" autocomplete="off">
        <div class="row">
          <div class="col-md-4 align-content-center text-center">
            <label for="valor" class="mb-2 compudev-text-gris mt-3"><span
                class="compudev-etiqueta compudev-bg-gris">1</span>
              &nbsp;Cantidad de dinero que quieres obtener:</label>
            <div style="margin-left: 20px; margin-right: 20px;" class="mb-4">
              <CurrencyInput id="valor" name="valor" class="form-control form-control-lg compudev-valor" v-model="valor"
                             @keyup="simulador"
                             :options="{locale: 'en-US', currency: 'USD', currencyDisplay: 'symbol', precision: 2, valueRange: { min: 50 }, }"/>
              <ErrorMessage name="valor" class="d-block compudev-error"/>
            </div>
          </div>
          <div class="col-md-4 align-content-center text-center">
            <div class="mb-3 compudev-text-gris mt-3"><span class="compudev-etiqueta compudev-bg-gris">2</span>&nbsp;&nbsp;Elige
              en
              cuánto tiempo obtenerlo:
            </div>
            <ul class="nav nav-tabs nav-tabs-red justify-content-center" role="tablist">
              <li class="nav-item text-center" role="tablist">
                <a id="tab-sim" class="nav-link active-red" data-bs-toggle="tab" href="#sim_anios"
                   role="tab" aria-controls="efectivo">AÑOS</a>
              </li>
            </ul>
            <div class="tab-content text-white" id="contenido-tab">
              <div class="tab-pane container fade show active" id="sim_anios" role="tabpanel" aria-labelledby="tab-sim">

                <div class="row row-cols-3 align-self-center mb-3 mt-3">
                  <div class="col compudev-text-right">
                    <input type="radio" class="btn-check" name="anios" id="sim_5" autocomplete="off" value="5"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(5)"/>
                    <label class="btn btn-outline-light" for="sim_5">&nbsp;5</label>
                  </div>
                  <div class="col">
                    <input type="radio" class="btn-check" name="anios" id="sim_10" autocomplete="off" value="10"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(10)"/>
                    <label class="btn btn-outline-light" for="sim_10">10</label>
                  </div>
                  <div class="col compudev-text-left">
                    <input type="radio" class="btn-check" name="anios" id="sim_20" autocomplete="off" value="20"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(20)"/>
                    <label class="btn btn-outline-light" for="sim_20">20</label>
                  </div>
                  <div class="col compudev-text-right">
                    <input type="radio" class="btn-check" name="anios" id="sim_30" autocomplete="off" value="30"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(30)"/>
                    <label class="btn btn-outline-light" for="sim_30">30</label>
                  </div>
                  <div class="col">
                    <input type="radio" class="btn-check" name="anios" id="sim_40" autocomplete="off" value="40"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(40)"/>
                    <label class="btn btn-outline-light" for="sim_40">40</label>
                  </div>
                  <div class="col compudev-text-left">
                    <input type="radio" class="btn-check" name="anios" id="sim_45" autocomplete="off" value="45"
                           v-model="anios"
                           @change="sel_sim_anios" @click="sel_sim_anios(45)"/>
                    <label class="btn btn-outline-light" for="sim_45">45</label>
                  </div>
                </div>
                <div class="row align-self-center">
                  <div class="col-10">
                    <div class="row">
                  <label class="col col-form-label compudev-text-right" for="txt_sim_anios">Otro Plazo</label>
                  <div class="col">
                    <div class="input-group">
                      <Field id="txt_sim_anios" class="form-control compudev-plazo" type="text"
                             name="txt_sim_anios"
                             v-model="txt_sim_anios" rules="required|numeric|min_value:1|max_value:45"
                             @keyup="text_sim_anios"/>
                      <span class="input-group-text">años</span>
                    </div>
                    <ErrorMessage name="txt_sim_anios" class="d-block compudev-error"/>
                  </div>
                    </div>
                  </div>
                </div>
                <div class="align-self-center">
                  <div class="mb-3 row justify-content-center">

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 align-content-center text-center compudev-text-gris">
            <div class="mb-2 mt-4">Para lograrlo debes invertir al mes</div>
            <div id="result-sim" class="card text-white compudev-bg-result-green mb-4 opacity-10">
              <div class="card-body" style="padding: 11px !important;">
                <div class="col align-self-center compudev-result-green">
                  <span>{{ result }} &nbsp;</span>
                </div>
              </div>
            </div>
            <div class="mb-2">Total invertido</div>
            <div id="result-acum" class="card text-white compudev-bg-result-red mb-4 opacity-10">
              <div class="card-body" style="padding: 5px !important;">
                <div class="col align-self-center compudev-result-red">
                  <span>{{ acumulado }} &nbsp;</span>
                </div>
              </div>
            </div>
            <div class="mb-2">Rendimiento proyectado en <strong v-if="plazo != null">{{ plazo + " años" }}
              &nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;</strong><span>Fondo Multiplica</span>
            </div>
            <div id="result-gana" class="card text-white compudev-bg-result-red mb-3 opacity-10">
              <div class="card-body" style="padding: 5px !important;">
                <div class="col align-self-center compudev-result-red">
                  <span>{{ ganancia }} &nbsp;</span>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Form>
    </div>
  </section>
</template>

<script>
import {Form, Field, ErrorMessage} from 'vee-validate'
import CurrencyInput from '@/components/CurrencyInput'

const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
export default {
  name: "SimuladorInv",
  components: {
    Form,
    Field,
    ErrorMessage,
    CurrencyInput
  },
  data() {
    return {
      tasa_anual: 0.0725,
      valor: null,
      txt_sim_anios: null,
      anios: null,
      plazo: null,
      result: null,
      acumulado: null,
      ganancia: null,
    }
  },
  methods: {
    sel_sim_anios(val = null) {
      if (val != null) {
        this.plazo = val
      }
      this.plazo = this.anios
      this.simulador()
    },
    text_sim_anios() {
      if (this.txt_sim_anios >= 1 && this.txt_sim_anios != null) {
        this.plazo = this.txt_sim_anios
        this.simulador()
      } else {
        this.result = null
        this.acumulado = null
        this.ganancia = null
        this.mostrar_result_sim(false)
      }
    },
    mostrar_result_sim(op) {
      if (op) {
        this.el1.classList.remove('opacity-10')
        this.el2.classList.remove('opacity-10')
        this.el3.classList.remove('opacity-10')
      } else {
        this.el1.classList.add('opacity-10')
        this.el2.classList.add('opacity-10')
        this.el3.classList.add('opacity-10')
      }
    },
    simulador() {
      if (this.valor != null && this.plazo != null) {
        const meses = this.plazo * 12
        const i = this.tasa_anual / 360
        const N = this.plazo * 360
        this.deposito = ((this.valor * i) / (((1 + i) ** N) - 1)) * 30
        this.result = formatter.format(this.deposito)
        this.acumulado = formatter.format(this.deposito.toFixed(2) * meses)
        this.ganancia = formatter.format(this.valor - (this.deposito.toFixed(2) * meses))
        this.mostrar_result_sim(true)
      } else {
        this.mostrar_result_sim(false)
      }
    }
  },
  mounted() {
    this.el1 = document.getElementById('result-sim')
    this.el2 = document.getElementById('result-acum')
    this.el3 = document.getElementById('result-gana')
  }
}
</script>
