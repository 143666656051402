/* eslint-disable */
<template>
  <section class="text-white bg-dark-alt">
    <div class="py-5">
      <h4 class="text-white text-center mb-3"><u>ELIGE EL FONDO DE TU PREFERENCIA</u></h4>
      <p class="text-white text-center">Gana mayor interés y cumple tus metas depositando tu dinero en plazos fijos</p>
      <h5 class="text-white text-center mb-4">Calcula cuánto ganarás a través de cada fondo:</h5>
        <ul class="nav nav-tabs justify-content-center" role="tablist">
          <li class="nav-item nav-item-global text-center" role="tablist">
            <a id="tab-30" class="nav-link active" data-bs-toggle="tab" href="#efectivo"
              role="tab" aria-controls="efectivo">FONDO EFECTIVO <br/>30 días</a>
          </li>
          <li class="nav-item nav-item-global text-center" role="tablist">
            <a id="tab-90" class="nav-link" data-bs-toggle="tab" href="#liquidez"
               role="tab" aria-controls="liquidez">FONDO LIQUIDEZ <br/>90 días</a>
          </li>
          <li class="nav-item nav-item-global text-center" role="tablist">
            <a id="tab-180" class="nav-link" data-bs-toggle="tab" href="#activo"
               role="tab" aria-controls="activo">FONDO ACTIVO <br/>180 días</a>
          </li>
          <li class="nav-item nav-item-global text-center" role="tablist">
            <a id="tab-365" class="nav-link " data-bs-toggle="tab" href="#multiplica"
               role="tab" aria-controls="multiplica">FONDO MULTIPLICA <br/>365 días</a>
          </li>
        </ul>
      <div class="tab-content text-white" id="contenido-tab">
        <!-- EFECTIVO -->
        <div class="tab-pane container fade show active" id="efectivo" role="tabpanel" aria-labelledby="tab-30">
          <FondoEfectivo />
        </div>
        <!-- LIQUIDEZ  -->
        <div class="tab-pane container fade" id="liquidez" role="tabpanel" aria-labelledby="tab-90">
          <FondoLiquidez />
        </div>
        <!-- ACTIVO -->
        <div class="tab-pane container fade" id="activo" role="tabpanel" aria-labelledby="tab-180">
          <FondoActivo />
        </div>
        <!-- MULTIPLICA -->
        <div class="tab-pane container fade" id="multiplica" role="tabpanel" aria-labelledby="tab-365">
          <FondoMultiplica />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FondoEfectivo from "@/components/FondoEfectivo";
import FondoMultiplica from "@/components/FondoMultiplica"
import FondoLiquidez from "@/components/FondoLiquidez"
import FondoActivo from "@/components/FondoActivo.vue";
export default {
  name: "FondoRendimiento",
  components: {
    FondoActivo,
    FondoEfectivo,
    FondoLiquidez,
    FondoMultiplica
  },
  methods: {
    initTabs: function(){
      document.querySelectorAll(".nav-tabs-responsive").forEach((el) => {
        // Prevent multiple init from codepen
        if(el.dataset.responsiveTabs) {
          return;
        }
        el.dataset.ResponsiveTabs = true;
        // This only works if the nav is visible on page load
        let totalWidth = 0;

        el.querySelectorAll("li").forEach((tab) => {
          totalWidth += tab.offsetWidth;
        });
        el.style.visibility = "visible";
        el.dataset.tabsWidth = totalWidth;

        // Create mobile menu
        let menu = document.createElement("ul");
        menu.classList.add("dropdown-menu");
        el.querySelectorAll("a").forEach((link) => {
          let newChild = document.createElement("li");
          let newChildLink = document.createElement("a");
          newChild.append(newChildLink);
          newChildLink.classList.add("dropdown-item");
          newChildLink.innerHTML = link.innerHTML;
          if (link.classList.contains("disabled")) {
            newChildLink.classList.add("disabled");
          }
          // Forwards click to avoid binding stuff twice
          newChildLink.addEventListener("click", (ev) => {
            ev.preventDefault();
            link.dispatchEvent(new Event("click"));
          });
          menu.append(newChild);
        });
        el.parentElement.append(menu);

        // Register observer to trigger responsive layout

        const resizeObserver = new ResizeObserver((entries) => {
          for (let entry of entries) {
            const tabs = entry.target.querySelector(".nav-tabs-responsive");
            // check inlineSize (width) or blockSize (height)
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
                ? entry.contentBoxSize[0]
                : entry.contentBoxSize;

            console.log(contentBoxSize);
            const size = contentBoxSize.inlineSize - 30;
            if (size < tabs.dataset.tabsWidth) {
              tabs.classList.add("nav-tabs-dropdown");
            } else if (size >= tabs.dataset.tabsWidth) {
              tabs.classList.remove("nav-tabs-dropdown");
              menu.style.display = "none";
            }
          }
        });

        console.log("attach observer");
        resizeObserver.observe(el.parentElement);

        // Handle responsive clicks
        el.querySelectorAll("a.nav-link").forEach((a) => {
          // eslint-disable-next-line
          a.addEventListener("click", (ev) => {
            if (!a.classList.contains("active")) {
              return;
            }
            if (
                !a.parentElement.parentElement.classList.contains("nav-tabs-dropdown")
            ) {
              return;
            }
            // Toggle menu
            if (menu.style.display == "block") {
              menu.style.display = "none";
            } else {
              menu.style.display = "block";
              menu.style.right = "0px";
              menu.style.top = a.parentElement.offsetHeight + "px";
            }
          });
        });
      });
    }
  },
  created() {
    this.initTabs()
  }

}
</script>

<style scoped>

</style>
