import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { defineRule, configure } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n'
import { required, numeric, min_value, max_value, between } from '@vee-validate/rules'
import "./assets/scss/custom.scss"


defineRule('required', required)
defineRule('numeric', numeric)
defineRule('min_value', min_value)
defineRule('max_value', max_value)
defineRule('between', between)

configure({
    generateMessage: localize('es', {
        messages: {
            required: "Debe ingresar un valor",
            numeric: "Ingrese un número",
            min_value: "Debe ser mayor a 0:{min}",
            max_value: "Debe ser menor a 0:{max}",
            between: "El valor debe ser entre 0:{min} y 1:{max}"
        }
    })
})
setLocale('es')

const app = createApp(App)
app.mount('#app')

import "bootstrap/dist/js/bootstrap"