/* eslint-disable */
<template>
  <FondoRendimiento />
  <SimuladorInv />
</template>

<script>

import FondoRendimiento from "@/components/FondoRendimiento";
import SimuladorInv from "@/components/SimuladorInv"

export default {
  name: 'App',
  components: {
    FondoRendimiento,
    SimuladorInv
  }
}
</script>

<style lang="scss">

</style>
