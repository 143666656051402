<template>
  <Form id="frm_multiplica" class="row g-3 justify-content-center frm" @submit="multiplica" autocomplete="off">
    <div class="row">
      <div class="col-md-6">
        <div id="result-liq" class="card text-white bg-secondary mb-5">
          <div class="card-body">
            <div class="align-content-center compudev-text-etiqueta-white p-3">
              <span class="compudev-etiqueta">1</span>&nbsp;&nbsp;Indica el Monto Inicial (desde $100)
            </div>
            <div style="margin-left: 20px; margin-right: 20px;">
              <CurrencyInput id="inicial" name="inicial" class="form-control form-control-lg compudev-valor-60" v-model="inicial"
                             @keyup="multiplica"
                             :options="{locale: 'en-US', currency: 'USD', currencyDisplay: 'symbol', precision: 2, valueRange: { min: 100 }, }"/>
              <ErrorMessage name="inicial" class="d-block compudev-error"/>
            </div>

            <div class="align-content-center compudev-text-etiqueta-white p-3">
              <span class="compudev-etiqueta">2</span>&nbsp;&nbsp;¿Cuánto será tu aporte extra mensual?
            </div>
            <div style="margin-left: 20px; margin-right: 20px;">
              <CurrencyInput id="txt_valor" name="txt_valor" class="form-control form-control-lg compudev-valor-60" v-model="txt_valor"
                             @keyup="is_valor_null" :rules="isValue"
                             :options="{locale: 'en-US', currency: 'USD', currencyDisplay: 'symbol', precision: 2,}"/>
              <ErrorMessage name="txt_valor" class="d-block compudev-error"/>
            </div>
            &nbsp;
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-10 offset-md-1">
            <div class="align-content-center compudev-text-etiqueta mb-2">
              <span class="compudev-etiqueta">3</span>&nbsp;&nbsp;Elige tiempo de permanencia
            </div>
            <ul class="nav nav-tabs nav-tabs-red justify-content-center" role="tablist">
              <li class="nav-item text-center" role="tablist">
                <a id="tab-30" class="nav-link active-red" data-bs-toggle="tab" href="#sel-dias"
                   role="tab" aria-controls="efectivo">AÑOS</a>
              </li>
            </ul>
            <div class="tab-content text-white" id="contenido-tab">
              <div class="tab-pane fade show active compudev-pt" id="sel-dias" role="tabpanel" aria-labelledby="tab-30">

                <div class="d-flex flex-row mb-3 justify-content-center flexbox">
                  <div>
                    <input type="radio" class="btn-check" name="anios" id="1" autocomplete="off" value="1"
                           v-model="anios"
                           @click="sel_anios(1)" @change="sel_anios"/>
                    <label class="btn btn-outline-light" for="1">&nbsp;1&nbsp;</label>
                  </div>
                  <div>
                    <input type="radio" class="btn-check" name="anios" id="2" autocomplete="off" value="2"
                           v-model="anios"
                           @click="sel_anios(2)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="2">&nbsp;2&nbsp;</label>
                  </div>
                  <div >
                    <input type="radio" class="btn-check" name="anios" id="3" autocomplete="off" value="3"
                           v-model="anios"
                           @click="sel_anios(3)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="3">&nbsp;3&nbsp;</label>
                  </div>
                  <div>
                    <input type="radio" class="btn-check" name="anios" id="4" autocomplete="off" value="4"
                           v-model="anios"
                           @click="sel_anios(4)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="4">&nbsp;4&nbsp;</label>
                  </div>
                  <div>
                    <input type="radio" class="btn-check" name="anios" id="5" autocomplete="off" value="5"
                           v-model="anios"
                           @click="sel_anios(5)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="5">&nbsp;5&nbsp;</label>
                  </div>
                  <div>
                    <input type="radio" class="btn-check" name="anios" id="10" autocomplete="off" value="10"
                           v-model="anios"
                           @click="sel_anios(10)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="10">10</label>
                  </div>
                  <div >
                    <input type="radio" class="btn-check" name="anios" id="15" autocomplete="off" value="15"
                           v-model="anios"
                           @click="sel_anios(15)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="15">15</label>
                  </div>
                  <div >
                    <input type="radio" class="btn-check" name="anios" id="20" autocomplete="off" value="20"
                           v-model="anios"
                           @click="sel_anios(20)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="20">20</label>
                  </div>
                  <div >
                    <input type="radio" class="btn-check" name="anios" id="25" autocomplete="off" value="25"
                           v-model="anios"
                           @click="sel_anios(25)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="25">25</label>
                  </div>
                  <div >
                    <input type="radio" class="btn-check" name="anios" id="30a" autocomplete="off" value="30"
                           v-model="anios"
                           @click="sel_anios(30)" @change="sel_anios" />
                    <label class="btn btn-outline-light" for="30a">30</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center text-center mt-3">
      <div class="col-md-4">
        <div class="compudev-text-etiqueta mb-2">Total invertido en {{ anios }} años</div>
        <div id="result-inv" class="card text-white compudev-bg-result-red mb-3 opacity-10">
          <div class="card-body">
            <div class="col align-self-center compudev-result">
              <span>{{ invertido }} &nbsp;</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="compudev-text-etiqueta mb-2">Rendimiento proyectado del 7,25%</div>
        <div id="result-int" class="card text-white compudev-bg-result-red mb-3 opacity-10">
          <div class="card-body">
            <div class="col align-self-center compudev-result">
              <span>{{ int_ganado }} &nbsp;</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="compudev-text-etiqueta mb-2">Monto total a recibir</div>
        <div id="result-recibir" class="card text-white compudev-bg-result-green mb-3 opacity-10">
          <div class="card-body">
            <div class="col align-self-center compudev-result">
              <span>{{ result }} &nbsp;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import {Form, ErrorMessage} from 'vee-validate'
import CurrencyInput from '@/components/CurrencyInput'

const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
export default {
  name: "FondoMultiplica",
  components: {
    Form,
    ErrorMessage,
    CurrencyInput
  },
  data() {
    return {
      interes: 0.0725,
      inicial: null,
      txt_valor: 0,
      valor: 0,
      plazo: null,
      txt_anios: null,
      invertido: null,
      anios: null,
      result: null,
      tasa: null,
      int_ganado: null,
      el: null,
    }
  },
  methods: {
    sel_anios(val = null){
      if (val != null) {
        this.plazo = val
      }
      this.plazo = this.anios
      this.multiplica()
    },
    isValue(value){
      if (value > 0 && value < 25){
        return 'Ingrese un valor mayor a 25';
      }else{
        return '';
      }
    },
    mostrar_result(op){
      if(op){
        this.el1.classList.remove('opacity-10')
        this.el2.classList.remove('opacity-10')
        this.el3.classList.remove('opacity-10')
      }else{
        this.el1.classList.add('opacity-10')
        this.el2.classList.add('opacity-10')
        this.el3.classList.add('opacity-10')
        this.result = null
        this.invertido = null
        this.int_ganado = null
      }
    },
    is_valor_null(){
      if (this.txt_valor == null || this.txt_valor === ''){
        this.valor = 0
        this.txt_valor = '0'
      }else {
        this.valor = parseFloat(this.txt_valor)
      }
      this.multiplica()
    },
    multiplica() {
      if (this.inicial != null && this.plazo != null && (this.valor === 0 || this.valor >= 25)) {
        this.txt_valor = this.valor.toString()
        this.tasa = (1 + this.interes) ** (1 / 12) - 1
        const result = ((this.valor * (1 + this.interes / 360) ** (30 * (this.plazo * 12 - 1)) * (1 + this.interes / 360) ** 30) - this.valor) / ((1 + this.interes / 360) ** 30 - 1) - this.valor + this.inicial * (1 + this.interes / 360) ** (30 * this.plazo * 12)
        this.result = formatter.format(result)
        const invertido = this.inicial + ((this.plazo * 12) - 1) * this.valor
        this.invertido = formatter.format(invertido)
        this.int_ganado = formatter.format(result - invertido)
        this.mostrar_result(true)
      }else{
        this.mostrar_result(false)
      }
    }
  },
  mounted() {
    this.el1 = document.getElementById('result-inv')
    this.el2 = document.getElementById('result-int')
    this.el3 = document.getElementById('result-recibir')
  },
}
</script>

<style scoped>

</style>
