<template>
  <Form id="frm_efectivo" class="row g-3 frm" @submit="efectivo" autocomplete="off">
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-3">
        <span class="compudev-etiqueta">1</span>&nbsp;&nbsp;Indica el Monto Inicial (desde $500)
      </div>
      <div style="margin-left: 20px; margin-right: 20px;" class="mb-4">
        <CurrencyInput id="valor" name="valor" class="form-control form-control-lg compudev-valor" v-model="valor"
                       @keyup="efectivo"
                       :options="{locale: 'en-US', currency: 'USD', currencyDisplay: 'symbol', precision: 2, valueRange: { min: 500 }, }"/>
        <ErrorMessage name="valor" class="d-block compudev-error"/>
      </div>
    </div>
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-3">
        <span class="compudev-etiqueta">2</span>&nbsp;&nbsp;Elige tiempo de permanencia
      </div>
      <ul class="nav nav-tabs nav-tabs-red justify-content-center" role="tablist">
        <li class="nav-item text-center" role="tablist">
          <a id="tab-30" class="nav-link active-red" data-bs-toggle="tab" href="#sel-dias"
             role="tab" aria-controls="efectivo">DÍAS</a>
        </li>
      </ul>
      <div class="tab-content text-white" id="contenido-tab">
        <div class="tab-pane container fade show active" id="sel-dias" role="tabpanel" aria-labelledby="tab-30">
          <div class="container text-center compudev-pt">
            <div class="col align-self-center mb-3">
              <input type="radio" class="btn-check" name="dias" id="30" autocomplete="off" value="30" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(30)"/>
              <label class="btn btn-outline-light" for="30">30</label>
              <input type="radio" class="btn-check" name="dias" id="60" autocomplete="off" value="60" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(60)"/>
              <label class="btn btn-outline-light" for="60">60</label>
              <input type="radio" class="btn-check" name="dias" id="89" autocomplete="off" value="89" v-model="plazo"
                     @change="sel_dias" @click="sel_dias(89)"/>
              <label class="btn btn-outline-light" for="89">89</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="align-content-center compudev-text-etiqueta mb-4">
        Con un rendimiento proyectado del {{ tasa_anual * 100 }}% en {{ dias }} días recibirás
      </div>
        <div style="margin-left: 20px; margin-right: 20px;">
          <div id="result-efe" class="card text-white compudev-bg-result-green mb-3 opacity-10">
            <div class="card-body">
              <div class="col align-self-center compudev-result">
                <span>{{ result }} &nbsp;</span>
              </div>
            </div>
          </div>
        </div>

    </div>
  </Form>
</template>

<script>
import { Form, ErrorMessage  } from 'vee-validate'
import CurrencyInput from '@/components/CurrencyInput'
const formatter = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})
export default {
  name: "FondoEfectivo",
  components: {
    Form,
    ErrorMessage,
    CurrencyInput
  },
  data() {
    return {
      tasa_anual: 0.06,
      mostrar: false,
      valor: null,
      txt_dias: null,
      dias: null,
      deposito_mensual: 0.00,
      plazo: null,
      result: null,
      tasa_mensual: 0.00,
      tasa_diaria: 0.00,
      rendimiento: 0.00,
      acumulado: 0.00,
      meses: null,
      el: null,
    }
  },
  methods: {
    sel_dias(val = null){
      if (val != null){
        this.dias = val
      }
      this.dias = this.plazo
      this.meses = this.get_mes(parseInt(this.dias))
      this.efectivo()
    },
    get_mes(val){
      let resul = null
      switch (val) {
        case 30:
          resul = 1
              break
        case 60:
          resul = 2
              break
        case 89:
          resul = 3
              break
      }
      return resul
    },
    efectivo(){
      if (this.valor != null && this.dias != null) {
        this.tasa_mensual = (1 + this.tasa_anual) ** (1 / 12) - 1
        this.tasa_diaria = (1 + this.tasa_anual) ** (1 / 360) - 1
        //const k1 = ((1 + this.tasa_mensual) ** 0 - 1) / this.tasa_mensual + this.valor * (1 + this.tasa_mensual) //** 0
        //this.acumulado = k1 * (1 + this.tasa_diaria) ** this.dias

        this.acumulado = ((0 * (1 + this.tasa_anual/360)**(30 * (this.meses - 1)) * (1 + this.tasa_anual/360) ** 30) - 0) / ((1 + this.tasa_anual / 360) ** 30 - 1) - 0 + this.valor * (1 + this.tasa_anual / 360) ** (30 * this.meses)

        this.result = formatter.format(this.acumulado)
        this.mostrar = true
        this.el.classList.remove('opacity-10')
      }
    }
  },
  mounted() {
    this.el = document.getElementById('result-efe')
  },
}
</script>

<style scoped>

</style>
